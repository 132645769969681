
import { Options, Vue } from "vue-class-component";

// Components
import { ElConfigProvider } from "element-plus";

// Locale
import zhCn from "element-plus/dist/locale/hr.js";

@Options({
  components: {
    ElConfigProvider,
  },
})
export default class App extends Vue {
  locale = zhCn;
}
