export interface AppCommonOrganizationMember {
  id: string;
  user_id: string;
  organization_id: string;
  is_selected: boolean;
  role: string;
}

export class AppCommonOrganizationMember implements AppCommonOrganizationMember {
  id: string;
  user_id: string;
  organization_id: string;
  is_selected: boolean;
  role: string;

  constructor() {
    this.id = "";
    this.user_id = "";
    this.organization_id = "";
    this.is_selected = false;
    this.role = "";
  }
}