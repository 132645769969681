export interface AuthenticationLoginResponse {
  id: string;
  email: string;
  token: string;
}

export class AuthenticationLoginResponse implements AuthenticationLoginResponse {
  id: string;
  email: string;
  token: string;

  constructor() {
    this.id = "";
    this.email = "";
    this.token = "";
  }
}