import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import store from "@/store/index";

// Components
import HomeIndex from "../views/home/index.vue";

const routerRedirectToDashboard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  switch (store.getters.getOrganizationMember.role) {
    case "administrator":
      next({ name: "AppAdministratorDashboardRootIndexVue" });
      break;
    case "coach":
      next({ name: "AppCoachDashboardRootIndexVue" });
      break;
    case "fitness-coach":
      next({ name: "AppCoachDashboardRootIndexVue" });
      break;
    case "manager":
      next({ name: "AppCoachDashboardRootIndexVue" });
      break;
    case "mentor":
      next({ name: "AppCoachDashboardRootIndexVue" });
      break;
    case "player":
      next({ name: "AppPlayerDashboardRootIndexVue" });
      break;
  }
};

const routerGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.meta.protectedRoute && store.getters.isAuthenticated) {
    next();
  } else if (to.meta.protectedRoute && !store.getters.isAuthenticated) {
    next({ name: "HomeIndexVue" });
  } else if (!to.meta.protectedRoute && store.getters.isAuthenticated) {
    routerRedirectToDashboard(to, from, next);
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: HomeIndex,
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/login",
    name: "AuthenticationLoginIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/authentication/login/index.vue"),
    beforeEnter: routerGuard,
  },
  // {
  //   path: "/authentication/registration",
  //   name: "AuthenticationRegistrationIndexVue",
  //   meta: {
  //     protectedRoute: false,
  //   },
  //   component: () => import(/* webpackChunkName: "registration" */ "../views/authentication/registration/index.vue"),
  //   beforeEnter: routerGuard,
  // },
  // {
  //   path: "/authentication/account-verification/:code",
  //   name: "AuthenticationAccountVerificationIndexVue",
  //   meta: {
  //     protectedRoute: false,
  //   },
  //   component: () => import(/* webpackChunkName: "account-verification" */ "../views/authentication/account-verification/index.vue"),
  //   beforeEnter: routerGuard,
  // },
  // {
  //   path: "/authentication/password-recovery",
  //   name: "AuthenticationPasswordRecoveryIndexVue",
  //   meta: {
  //     protectedRoute: false,
  //   },
  //   component: () => import(/* webpackChunkName: "password-recovery" */ "../views/authentication/password-recovery/index.vue"),
  //   beforeEnter: routerGuard,
  // },
  // {
  //   path: "/authentication/password-recovery-verification/:code",
  //   name: "AuthenticationPasswordRecoveryVerificationIndexVue",
  //   meta: {
  //     protectedRoute: false,
  //   },
  //   component: () => import(/* webpackChunkName: "password-recovery-verification" */ "../views/authentication/password-recovery-verification/index.vue"),
  //   beforeEnter: routerGuard,
  // },
  {
    path: "/app/organization-wizard",
    name: "AppOrganizationWizardIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-organization-wizard" */ "../views/app/organization-wizard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/organizations",
    name: "AppAdministratorOrganizationsRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-organizations-root" */ "../views/app/administrator/organizations/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/organizations/:id",
    name: "AppAdministratorOrganizationsDetailIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-organizations-detail" */ "../views/app/administrator/organizations/detail/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/dashboard",
    name: "AppAdministratorDashboardRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-dashboard-root" */ "../views/app/administrator/dashboard/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/dashboard/statistics",
    name: "AppAdministratorDashboardStatisticsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-dashboard-statistics" */ "../views/app/administrator/dashboard/statistics/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/dashboard/calendar",
    name: "AppAdministratorDashboardCalendarIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-dashboard-calendar" */ "../views/app/administrator/dashboard/calendar/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/health-records",
    name: "AppAdministratorHealthRecordsRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-health-records-root" */ "../views/app/administrator/health-records/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/trainings",
    name: "AppAdministratorTrainingsRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-trainings-root" */ "../views/app/administrator/trainings/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/trainings/categories",
    name: "AppAdministratorTrainingsCategoriesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-trainings-categories" */ "../views/app/administrator/trainings/categories/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/diagnostics",
    name: "AppAdministratorDiagnosticsRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-diagnostics-root" */ "../views/app/administrator/diagnostics/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/diagnostics/categories",
    name: "AppAdministratorDiagnosticsCategoriesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-diagnostics-categories" */ "../views/app/administrator/diagnostics/categories/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/matches",
    name: "AppAdministratorMatchesRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-matches-root" */ "../views/app/administrator/matches/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/matches/categories",
    name: "AppAdministratorMatchesCategoriesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-matches-categories" */ "../views/app/administrator/matches/categories/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/players",
    name: "AppAdministratorPlayersRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-players-root" */ "../views/app/administrator/players/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/players/categories",
    name: "AppAdministratorPlayersCategoriesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-players-categories" */ "../views/app/administrator/players/categories/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/players/profile/:id",
    name: "AppAdministratorPlayersProfileRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-players-profile-root" */ "../views/app/administrator/players/profile/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/players/profile/:id/statistics",
    name: "AppAdministratorPlayersProfileStatisticsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-players-profile-statistics" */ "../views/app/administrator/players/profile/statistics/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/players/profile/:id/matches",
    name: "AppAdministratorPlayersProfileMatchesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-players-profile-matches" */ "../views/app/administrator/players/profile/matches/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/players/profile/:id/trainings",
    name: "AppAdministratorPlayersProfileTrainingsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-players-profile-trainings" */ "../views/app/administrator/players/profile/trainings/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/players/profile/:id/diagnostics",
    name: "AppAdministratorPlayersProfileDiagnosticsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-players-profile-diagnostics" */ "../views/app/administrator/players/profile/diagnostics/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/players/profile/:id/health-records",
    name: "AppAdministratorPlayersProfileHealthRecordsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-players-profile-health-records" */ "../views/app/administrator/players/profile/health-records/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/players/profile/:id/tips",
    name: "AppAdministratorPlayersProfileTipsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-players-profile-tips" */ "../views/app/administrator/players/profile/tips/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/players/profile/:id/notes",
    name: "AppAdministratorPlayersProfileNotesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-players-profile-notes" */ "../views/app/administrator/players/profile/notes/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/resources",
    name: "AppAdministratorResourcesRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-resources-root" */ "../views/app/administrator/resources/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/resources/supplements",
    name: "AppAdministratorResourcesSupplementsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-resources-supplements" */ "../views/app/administrator/resources/supplements/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/resources/diets",
    name: "AppAdministratorResourcesDietsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-resources-diets" */ "../views/app/administrator/resources/diets/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administrator/resources/exercises",
    name: "AppAdministratorResourcesExercisesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administrator-resources-exercises" */ "../views/app/administrator/resources/exercises/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/dashboard",
    name: "AppCoachDashboardRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-dashboard-root" */ "../views/app/coach/dashboard/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/dashboard/statistics",
    name: "AppCoachDashboardStatisticsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-dashboard-statistics" */ "../views/app/coach/dashboard/statistics/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/dashboard/calendar",
    name: "AppCoachDashboardCalendarIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-dashboard-calendar" */ "../views/app/coach/dashboard/calendar/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/health-records",
    name: "AppCoachHealthRecordsRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-health-records-root" */ "../views/app/coach/health-records/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/trainings",
    name: "AppCoachTrainingsRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-trainings-root" */ "../views/app/coach/trainings/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/trainings/categories",
    name: "AppCoachTrainingsCategoriesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-trainings-categories" */ "../views/app/coach/trainings/categories/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/diagnostics",
    name: "AppCoachDiagnosticsRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-diagnostics-root" */ "../views/app/coach/diagnostics/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/diagnostics/categories",
    name: "AppCoachDiagnosticsCategoriesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-diagnostics-categories" */ "../views/app/coach/diagnostics/categories/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/matches",
    name: "AppCoachMatchesRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-matches-root" */ "../views/app/coach/matches/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/matches/categories",
    name: "AppCoachMatchesCategoriesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-matches-categories" */ "../views/app/coach/matches/categories/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/players",
    name: "AppCoachPlayersRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-players-root" */ "../views/app/coach/players/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/players/categories",
    name: "AppCoachPlayersCategoriesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-players-categories" */ "../views/app/coach/players/categories/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/players/profile/:id",
    name: "AppCoachPlayersProfileRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-players-profile-root" */ "../views/app/coach/players/profile/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/players/profile/:id/statistics",
    name: "AppCoachPlayersProfileStatisticsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-players-profile-statistics" */ "../views/app/coach/players/profile/statistics/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/players/profile/:id/matches",
    name: "AppCoachPlayersProfileMatchesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-players-profile-matches" */ "../views/app/coach/players/profile/matches/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/players/profile/:id/trainings",
    name: "AppCoachPlayersProfileTrainingsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-players-profile-trainings" */ "../views/app/coach/players/profile/trainings/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/players/profile/:id/diagnostics",
    name: "AppCoachPlayersProfileDiagnosticsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-players-profile-diagnostics" */ "../views/app/coach/players/profile/diagnostics/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/players/profile/:id/health-records",
    name: "AppCoachPlayersProfileHealthRecordsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-players-profile-health-records" */ "../views/app/coach/players/profile/health-records/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/players/profile/:id/tips",
    name: "AppCoachPlayersProfileTipsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-players-profile-tips" */ "../views/app/coach/players/profile/tips/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/players/profile/:id/notes",
    name: "AppCoachPlayersProfileNotesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-players-profile-notes" */ "../views/app/coach/players/profile/notes/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/resources",
    name: "AppCoachResourcesRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-resources-root" */ "../views/app/coach/resources/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/resources/supplements",
    name: "AppCoachResourcesSupplementsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-resources-supplements" */ "../views/app/coach/resources/supplements/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/resources/diets",
    name: "AppCoachResourcesDietsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-resources-diets" */ "../views/app/coach/resources/diets/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/coach/resources/exercises",
    name: "AppCoachResourcesExercisesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-coach-resources-exercises" */ "../views/app/coach/resources/exercises/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/player/dashboard",
    name: "AppPlayerDashboardRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-player-dashboard-root" */ "../views/app/player/dashboard/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/player/matches",
    name: "AppPlayerMatchesRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-player-matches-root" */ "../views/app/player/matches/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/player/trainings",
    name: "AppPlayerTrainingsRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-player-trainings-root" */ "../views/app/player/trainings/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/player/health-records",
    name: "AppPlayerHealthRecordsRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-player-health-records-root" */ "../views/app/player/health-records/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/player/resources",
    name: "AppPlayerResourcesRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-player-resources-root" */ "../views/app/player/resources/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/player/resources/supplements",
    name: "AppPlayerResourcesSupplementsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-player-resources-supplements" */ "../views/app/player/resources/supplements/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/player/resources/diets",
    name: "AppPlayerResourcesDietsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-player-resources-diets" */ "../views/app/player/resources/diets/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/player/resources/exercises",
    name: "AppPlayerResourcesExercisesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-player-resources-exercises" */ "../views/app/player/resources/exercises/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/player/notes",
    name: "AppPlayerNotesRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-player-notes-root" */ "../views/app/player/notes/root/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/player/tips",
    name: "AppPlayerTipsRootIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-player-tips-root" */ "../views/app/player/tips/root/index.vue"),
    beforeEnter: routerGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes
});

export default router;
