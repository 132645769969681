
import { Options, Vue } from "vue-class-component";

// Components
import {
  ElContainer,
  ElRow,
  ElCol,
  ElDialog,
  ElButton,
} from "element-plus";

@Options({
  components: {
    ElContainer,
    ElRow,
    ElCol,
    ElDialog,
    ElButton,
  },
})
export default class HomeIndex extends Vue {
  isDialogVisibleImpressum: boolean | null = false;
  isDialogVisibleTermsOfUse: boolean | null = false;
  isDialogVisiblePrivacyPolicy: boolean | null = false;
}
