import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

// Models
import { AppCommonOrganizationMember } from "@/models/app/common/organization-member";
import { AuthenticationLoginResponse} from "@/models/authentication/login/response";

// const ls = new SecureLS({
//   isCompression: false
// });

export default createStore({
  plugins: [
    createPersistedState({
      key: "WERUNFOOTBALL",
      // storage: {
      //   // getItem: (key) => ls.get(key),
      //   // setItem: (key, value) => ls.set(key, value),
      //   // removeItem: (key) => ls.remove(key)
      // }
    }),
  ],
  state: {
    authentication: new AuthenticationLoginResponse(),
    organizationMember: new AppCommonOrganizationMember(),
    organizationMembership: []
  },
  getters: {
    isAuthenticated(state): boolean {
      return state.authentication.token !== "";
    },
    getAuthentication(state): any {
      return state.authentication;
    },
    getOrganizationMember(state): any {
      return state.organizationMember;
    },
    getOrganizationMembership(state): Array<any> {
      return state.organizationMembership;
    },
  },
  mutations: {
    setAuthentication(state: any, data: AuthenticationLoginResponse) {
      state.authentication = data;
    },
    setOrganizationMember(state: any, data: AppCommonOrganizationMember) {
      state.organizationMember = data;
    },
    setOrganizationMembership(state: any, data: Array<AppCommonOrganizationMember>) {
      state.organizationMembership = data;
    },
    setLogout(state: any) {
      state.authentication = new AuthenticationLoginResponse();
      state.organizationMember = new AppCommonOrganizationMember();
      state.organizationMembership = [];
    },
  },
  actions: {
    setAuthentication({ commit }, data: AuthenticationLoginResponse) {
      commit("setAuthentication", data);
    },
    setOrganizationMember({ commit }, data: AppCommonOrganizationMember) {
      commit("setOrganizationMember", data);
    },
    setOrganizationMembership({ commit }, data: Array<AppCommonOrganizationMember>) {
      commit("setOrganizationMembership", data);
    },
    setLogout({ commit }) {
      commit("setLogout");
    },
  },
});